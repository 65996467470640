let buffer = []

async function ascii (jsonPath) {
    if (window.fetch) {
        const response = await fetch(jsonPath)
        const json = await response.json()

        await pushToBuffer(json)
    } else {
        // use XHR
        const asciiReq = new XMLHttpRequest()
        asciiReq.addEventListener('load', async function () {
            const data = JSON.parse(this.responseText)
            await pushToBuffer(data)            
        })
        asciiReq.open('GET', jsonPath)
        asciiReq.send()
    }
}

async function pushToBuffer (data) {
    for (const step in data) {
        buffer.push(data[step])
    }
}

async function loop (frameMs = Polaris.config.ascii.frameTime || 33.3) {
    const buf = buffer;
    const output = document.querySelector('#ascii')
    let count = 0;

    setInterval(() => {
        output.innerHTML = buf[count]

        if (count === buf.length - 1) {
            // Restart
            count = 0
        } else count++
    }, frameMs)
}

export {
    ascii,
    loop
}