/*! 🎵 We ride the magic tide, through the night 🎵 */
import { get, set } from 'lodash'
import { ascii, loop } from './polaris-ascii'

setTimeout(console.log.bind(console, 'Polaris v1.1.0'), 0)

window.Polaris = window.Polaris || {
    ascii: {
        load: ascii,
        loop
    },
    config: {
        randomCharacter: {
            respectReducedMotion: true,
            onReducedMotionPlan: "RUN_CHARS_ONCE", // "RUN_CHARS_ONCE", "INCREASED_INTERVAL"
            intervalDefault: 75,
            intervalReducedMotion: null
        },
        search: {
            pages: ["work", "articles"],
            plan: "fuzzy",
            debounceTime: 300
        },
        ascii: {
            enabled: true,
            frameTime: 33.3, // 30FPS = 33.3ms, 60FPS = 16.7ms
            asciiPath: "/assets/steps.json"
        }
    },
    async _getRemoteConfig() {
        if (window.fetch) {
            const res = await fetch('/assets/polaris_config.json')

            if (res.ok) {
                const data = await res.json()
                Polaris.config = { ...Polaris.config, ...data }
            }
        } else {
            // use xhr
            const xhr = new XMLHttpRequest()
            xhr.open('GET', '/assets/polaris_config.json', true)
            xhr.onload = () => {
                if (xhr.status >= 200 && xhr.status < 400) {
                    const data = JSON.parse(xhr.responseText)
                    Polaris.config = { ...Polaris.config, ...data }
                }
            }
            xhr.send()
        }
    },
    _chars () {
        document.querySelectorAll('.random-chars').forEach(e => e.textContent = Math.random().toString(36).slice(2))
    },
    initializeRandomChars() {
        const prefersReducedMotion = window.matchMedia('(prefers-reduced-motion: reduce)')
        let interval;
        if (Polaris.config.randomCharacter.respectReducedMotion && (!prefersReducedMotion || prefersReducedMotion.matches)) {
            // Run _chars once
            console.log('running _chars() once due to motion reduced mode being triggered')
            Polaris._chars()
        } else {
            interval = setInterval(() => Polaris._chars(), 75) // todo: this might be too much
        }

        prefersReducedMotion.addEventListener('change', () => {
            if (Polaris.config.randomCharacter.respectReducedMotion) {
                if (prefersReducedMotion.matches) {
                    console.log('(prefers-reduced-motion: reduce) = true')
                    clearInterval(interval)
                    Polaris._chars()
                } else {
                    console.log('(prefers-reduced-motion: reduce) = false')
                    interval = setInterval(() => Polaris._chars(), 75)
                }
            }
        })
    }
}

document.addEventListener('DOMContentLoaded', async () => {
    await Polaris._getRemoteConfig()
    if (!window.POLARIS_NO_CHARS) Polaris.initializeRandomChars()

    if (Polaris.config.ascii.enabled) {
        await Polaris.ascii.load('/assets/steps.json')
        await Polaris.ascii.loop()
    }
})